import Cookie from "js-cookie";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { RestLink } from "apollo-link-rest";
import { withClientState } from "apollo-link-state";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import resolvers from "./resolvers";
// import formatReportsLink from "apollo/links/formatReportsLink";
// import { toIdValue } from "apollo-utilities";
const cache = new InMemoryCache({
  cacheRedirects: {
    // Query: {
    //   currentUser: (_, args) => {
    //     return toIdValue(
    //       cache.config.dataIdFromObject({
    //         __typename: "CurrentUser",
    //         id: args.id
    //       })
    //     );
    //   }
    // }
  }
});

const stateLink = withClientState({
  cache,
  ...resolvers
});

const setAuthHeadersLink = setContext((request, previousContext) => {
  let headers = {};
  if (request.operationName === "Login") {
    const { email, password } = request.variables;
    if (email && password) {
      let str = email + ":" + password,
        auth_string = btoa(unescape(encodeURIComponent(str)));
      headers = {
        Authorization: "Basic " + auth_string
      };
    }
  }
  return {
    headers
  };
});

const restLink = new RestLink({
  uri: process.env.REACT_APP_API_URL,
  endpoints: {
    mock: "https://private-c3fb2-socialcentiv1.apiary-mock.com"
  },
  credentials: "omit",
  headers: {
    "X-User-Email": Cookie.get("UserEmail") || "",
    "X-User-Token": Cookie.get("UserAuth") || "",
    "Content-type": "application/x-www-form-urlencoded",
    Accept: "application/vnd.socialcentiv.v2"
  }
});

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    if (graphQLErrors) console.log("[GraphQL error]: Message: ", graphQLErrors);
    if (networkError) console.log("[Network error]: ", networkError);
  }
);

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    stateLink,
    setAuthHeadersLink,
    // formatReportsLink,
    restLink
  ]),
  cache,
  setConnectToDevTools: true
});

client.onResetStore(stateLink.writeDefaults);

export { client };
export default client;
