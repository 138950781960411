import React, { Component } from "react";
import { ApolloProvider } from "react-apollo";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";

import asyncComponent from "components/utils/AsyncComponent";
import PublicRouter from "components/routers/PublicRouter";
import SessionRouter from "components/routers/SessionRouter";
import client from "apollo/client";
import { CurrentUser } from "apollo/actions/users";
import { muiTheme } from "styles";
import CONSTANTS from "./constants";

import "styles/main.css";
import * as Sentry from "@sentry/browser";

const { AppTypes, UserTypes } = CONSTANTS;

class RouterComponent extends Component {
  render() {
    const { currentUser } = this.props;
    let appStore = {};
    // check location host here and render correct app router
    let AppRouter;
    switch (currentUser.type) {
      case UserTypes.AGENCY_ADMIN:
      case UserTypes.AM:
        AppRouter = asyncComponent(() =>
          import("components/routers/AgencyRouter")
        );
        appStore.appType = AppTypes.AGENCY;
        break;
      // case "my":
      //   AppRouter = asyncComponent(() =>
      //     import("components/routers/AdvertiserRouter")
      //   );
      //   appStore.appType = AppTypes.ADVERTISER;
      //   break;
      // case "admin":
      //   AppRouter = asyncComponent(() =>
      //     import("components/routers/AdminRouter")
      //   );
      //   appStore.appType = AppTypes.ADMIN;
      //   break;
      // case "console":
      //   AppRouter = asyncComponent(() =>
      //     import("components/routers/ConsoleRouter")
      //   );
      //   appStore.appType = AppTypes.CONSOLE;
      //   break;
      default:
        break;
    }
    return <AppRouter {...this.props} />;
  }
}

class App extends Component {
  state = {
    email: undefined,
    loggedIn: false
  };
  render() {
    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={muiTheme}>
          <Router>
            <Switch>
              <Route path="/public" render={() => <PublicRouter />} />
              <Route path="/session" render={() => <SessionRouter />} />
              <Route
                render={() => (
                  <CurrentUser>
                    {({ data, loading }) => {
                      if (loading) return null;
                      if (data && data.currentUser) {
                        const { currentUser } = data;
                        Sentry.configureScope(scope =>
                          scope.setUser({
                            id: currentUser.id,
                            username: currentUser.name,
                            email: currentUser.email
                          })
                        );
                      }
                      return data ? (
                        <RouterComponent currentUser={data.currentUser} />
                      ) : (
                        <Redirect to="/session/login" />
                      );
                    }}
                  </CurrentUser>
                )}
              />
            </Switch>
          </Router>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  }
}

export default App;
