const CONSTANTS = {
  UserTypes: {
    AM: "AccountManager",
    AGENCY_ADMIN: "AgencyAdmin",
    IP: "InteractiveProducer",
    AD_ADMIN: "AdvertiserAdmin",
    AD_VIEWER: "AdvertiserViewer",
    SUPER_ADMIN: "SuperAdmin",
    RESPONDER: "Responder"
  },
  AppTypes: {
    CONSOLE: 1,
    RESPONDER: 2,
    AGENCY: 3,
    ADVERTISER: 4,
    ADMIN: 5
  },
  Filters: {
    INFLUENCERS: "influencers",
    PLATFORMS: "platforms",
    KEYWORDS: "keywords",
    ADMIN_ADVERTISER_RESPONDERS: "admin_advertiser_responders"
  },
  defaults: {},
  daysOfWeek: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ]
};

export default CONSTANTS;
