import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import asyncComponent from "components/utils/AsyncComponent";
import SessionLayout from "components/layouts/SessionLayout";

const routes = [
  {
    path: "/session/login",
    component: asyncComponent(() => import("components/pages/Login")),
    exact: true
  },
  {
    path: "/session/forgot-password",
    component: asyncComponent(() => import("components/pages/ForgotPassword")),
    exact: true
  },
  {
    path: "/session/reset-password",
    component: asyncComponent(() => import("components/pages/ResetPassword")),
    exact: true
  }
];

const SessionRouter = props => {
  console.log("Session router");
  return (
    <Switch>
      {routes.map(({ component: Component, ...rest }) => (
        <Route
          key={rest.path}
          {...rest}
          render={routerProps => (
            <SessionLayout {...routerProps}>
              <Component />
            </SessionLayout>
          )}
        />
      ))}
      <Route render={() => <Redirect to="/session/login" />} />
    </Switch>
  );
};

export default SessionRouter;
