import React, { Component } from "react";

export default function asyncComponent(importComponent, props) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
        mounted: false
      };
    }

    async componentDidMount() {
      this.setState({ mounted: true });
      const { default: component } = await importComponent();

      this.state.mounted &&
        this.setState({
          component: component
        });
    }

    componentWillUnmount() {
      this.state.mounted && this.setState({ mounted: false });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} {...props} /> : null;
    }
  }

  return AsyncComponent;
}
