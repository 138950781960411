import moment from "moment";

export default {
  defaults: {
    reportFilters: {
      __typename: "ReportFilters",
      influencers: ["active", "paused"],
      startDate:
        localStorage.getItem("reporting-start-date") ||
        moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
      endDate:
        localStorage.getItem("reporting-end-date") ||
        moment()
          .endOf("day")
          .format("YYYY-MM-DD"),
      platform: (
        localStorage.getItem("reporting-platform") || "instagram,youtube"
      ).split(",")
    }
  },
  resolvers: {
    Mutation: {
      updateReportFilters: (obj, args, { cache }) => {
        if (args.input.startDate) {
          localStorage.setItem("reporting-start-date", args.input.startDate);
        }
        if (args.input.endDate) {
          localStorage.setItem("reporting-end-date", args.input.endDate);
        }
        if (args.input.platform) {
          localStorage.setItem("reporting-platform", args.input.platform);
        }
        cache.writeData({
          data: {
            reportFilters: { __typename: "ReportFilters", ...args.input }
          }
        });
        return null;
      }
    }
  }
};
