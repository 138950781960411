import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import asyncComponent from "components/utils/AsyncComponent";

const routes = [
  {
    path: "/public/auth/:influencerId/instagram",
    component: asyncComponent(() => import("components/pages/AuthInstagram")),
    exact: true
  }
];

const PublicRouter = props => {
  console.log("Public router");
  return (
    <Switch>
      {routes.map(({ component: Component, ...rest }) => (
        <Route
          key={rest.path}
          {...rest}
          render={routerProps => <Component {...routerProps} />}
        />
      ))}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default PublicRouter;
