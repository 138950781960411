import { createMuiTheme } from "@material-ui/core/styles";

const Colors = {
  primary: "#ff8610",
  secondary: "#3d4573",
  tertiary: "#36cea0",
  dark: "#212121",
  darkBlue: "#006088",
  active: "#36cea0",
  paused: "#ffca28",
  archived: "#cfd8dc",
  success: "#36cea0",
  danger: "#d54800",
  tableHeader: "#eceff1",
  tableStripedRow: "#f7f6f6",
  tableRowHover: "#E6F4FA",
  gray: "#b0bec5",
  inputBorder: "#e0e0e0",
  graph1: "#374375",
  graph2: "#656e94",
  graph3: "#969cb6",
  buttonDefault: "#f5f5f5",
  grayText: "#9e9e9e",
  gray000: "#fafafa",
  gray100: "#f0f0f0",
  gray300: "#d7d7d7",
  gray400: "#585858",
  gray500: "#7d898d",
  gray700: "#383e3f",
  primaryBorderColor: "#c3d2e2",
  link: "#03a9f4"
};

const muiTheme = createMuiTheme({
  palette: {
    primary: { main: Colors.secondary },
    secondary: { main: Colors.primary }
  },
  typography: { useNextVariants: true },
  tabs: {
    backgroundColor: "#ffffff",
    textColor: Colors.secondary,
    selectedTextColor: Colors.primary
  },
  checkbox: {
    boxColor: Colors.inputBorder,
    checkedColor: Colors.primary
  },
  textField: {
    focusColor: Colors.primary,
    borderColor: Colors.inputBorder
  }
});

export { Colors, muiTheme };
export default {
  Colors,
  muiTheme
};
