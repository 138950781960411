import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import "./lib";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

Sentry.init({
  dsn: "https://0166dbb024ca42fb9ec4af1e0c046b49@sentry.io/1413893",
  environment: process.env.REACT_APP_ENV
});
ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
